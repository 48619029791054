var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Maintenance" }, [
    _c("img", {
      staticClass: "Maintenance__logo",
      attrs: { alt: "Bobtail's logo", src: require("../assets/logo.png") }
    }),
    _c("p", { staticClass: "fs-30 mb-30" }, [
      _vm._v(
        "\n    The Bobtail website is currently undergoing maintenance. We will be back soon!\n  "
      )
    ]),
    _vm.maintenanceReason
      ? _c("p", { staticClass: "fs-22 mb-30" }, [
          _vm._v("\n    " + _vm._s(_vm.maintenanceReason) + "\n  ")
        ])
      : _vm._e(),
    _c("p", { staticClass: "fs-22 mb-30" }, [
      _vm._v(
        "This page will auto-refresh when maintenance is over. We are sorry for any inconvenience!"
      )
    ]),
    _c("label", { staticClass: "fs-18 italic" }, [_vm._v("- The Bobtail Team")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }