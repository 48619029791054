<template>
  <div class="Maintenance">
    <img
      alt="Bobtail's logo"
      class="Maintenance__logo"
      src="../assets/logo.png"
    >

    <p class="fs-30 mb-30">
      The Bobtail website is currently undergoing maintenance. We will be back soon!
    </p>

    <p
      v-if="maintenanceReason"
      class="fs-22 mb-30"
    >
      {{ maintenanceReason }}
    </p>

    <p class="fs-22 mb-30">This page will auto-refresh when maintenance is over. We are sorry for any inconvenience!</p>

    <label class="fs-18 italic">- The Bobtail Team</label>
  </div>
</template>

<script>
// Helpers
import { Tenant } from '../utils/api'

export default {
  name: 'Maintenance',

  async created () {
    const { maintenance_reason } = (await Tenant.fetchMaintenanceMode()).data
    this.maintenanceReason = maintenance_reason
  },

  async mounted () {
    // check if still in maintenance mode
    this.maintenanceInterval = setInterval(async () => {
      const { maintenance_mode, maintenance_reason } = (await Tenant.fetchMaintenanceMode()).data
      this.maintenanceReason = maintenance_reason
      if (!maintenance_mode) window.open('/invoices/pending', '_self')
    }, 5000)
  },

  beforeDestroy() {
    clearInterval(this.maintenanceInterval)
  },

  data() {
    return {
      maintenanceInterval: null,
      maintenanceReason: null,
    }
  },
}
</script>

<style lang="sass">
.Maintenance
  margin: 0 auto
  margin-top: rem(90px)
  max-width: rem(768px + 32px) // 2rem for left and right padding
  padding: 0 1rem

  &__logo
    margin-bottom: rem(60px)
    width: rem(150px)
</style>
